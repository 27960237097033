// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Success from './Success';
import Error from './Error';

const App: React.FC = () => {
  return (
    <Router>
        <Routes>
          <Route path="/success" element={<Success />} />
          <Route path="/error" element={<Error />} />
          <Route path="/" element={<p>Select a route: <Link to="/success">Success</Link> | <Link to="/error">Error</Link></p>} />
        </Routes>
    </Router>
  );
};

export default App;
