import axios from 'axios';

const HOST_URL = "https://api.dev.trackyourimpact.io/";

const API = (accessToken?:string) => axios.create({
  baseURL: HOST_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'apptype': 'mobile',
    'app_type': 'mobile',
    'authtype': 'firebase',
    'Authorization': `Bearer ${accessToken}`
  },
});


export default API;